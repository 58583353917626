import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import SocialProfile from "../../../components/social-profile/social-profile";
import {
  IntroWrapper,
  IntroImage,
  IntroTitle,
  Desciption,
  IntroInfo,
} from "./style";
import { IoLogoFacebook, IoLogoTwitter, IoLogoInstagram } from "react-icons/io";

type IntroProps = {};

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: "https://www.facebook.com/mecaniques.org",
    tooltip: "Facebook",
  },
  {
    icon: <IoLogoTwitter />,
    url: "https://twitter.com/mecaniques101",
    tooltip: "Twitter",
  },
];

const Intro: React.FunctionComponent<IntroProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/author.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 210, maxHeight: 210, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  const AuthorImage = Data.avatar.childImageSharp.fluid;

  return (
    <IntroWrapper>
      <IntroImage>
        <Image fluid={AuthorImage} alt="author" />
      </IntroImage>
      <IntroInfo>
        <IntroTitle className="title">Des fragments d'espace-temps</IntroTitle>
        <Desciption>
          Mises à jour régulières, compléments, erratum, billets d'humeur,
          suivez toutes les nouvelles liées au livre,{" "}
          <a target="_blank" href="https://mecaniques.substack.com/">
            abonnez vous à la newsletter
          </a>{" "}
          et suivez nous sur les réseaux
        </Desciption>
        <SocialProfile items={SocialLinks} />
      </IntroInfo>
    </IntroWrapper>
  );
};

export default Intro;
